import React from "react"
import PropTypes from "prop-types"

const YoutubePlayer = ({ videoId, title }) => (
  <iframe
    className="youtube-video-player"
    loading="lazy"
    src={`https://www.youtube.com/embed/${videoId}`}
    title={title || "Youtube Player"}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
)

export default YoutubePlayer
YoutubePlayer.propTypes = { videoId: PropTypes.string, title: PropTypes.string }
