import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import ModalContainer from "@src/components/container-modal"
import YoutubePlayer from "@src/components/youtube-player"

const Modal = styled(ModalContainer)`
  padding: 0;
  margin: 0 !important;
  border-radius: 0;
  background: none;
  height: 240px;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  & > div {
    overflow-y: visible !important;
    border: none;
    background: none;
    padding: 0;
  }
  & .youtube-video-player {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  & > button {
    right: -8px;
    top: -8px;
    :hover {
      color: black;
    }
  }
  @media only screen and (${props => props.theme.screen.tablet.min}) {
    height: 480px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > button > i.icon {
      font-size: 20px;
      line-height: 20px;
    }
  }
`

const YoutubeModal = ({ id, videoId, title, isOpen, onClose }) => {
  return (
    <Modal id={id} isOpen={isOpen} onClose={onClose}>
      <YoutubePlayer videoId={videoId} title={title} />
    </Modal>
  )
}

export default YoutubeModal
YoutubeModal.propTypes = {
  id: PropTypes.string,
  videoId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
