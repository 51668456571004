const cloudfrontLink =
  "https://d3owbckoeihl9o.cloudfront.net/images/testimonials/"

export const why10ATestimonial = {
  url: "https://www.youtube.com/watch?v=cpkZ1EBeJ48",
  image: {
    src: `${cloudfrontLink}why10a/Testimonials%401x.png`,
    alt: `why10a-photo`,
    srcSet: `${cloudfrontLink}why10a/Testimonials%401x.png 1x, ${cloudfrontLink}why10a/Testimonials%402x.png 2x, ${cloudfrontLink}why10a/Testimonials%403x.png 3x`,
  },
}
export const testimonialPageData = [
  /*
  Fields to add:
  1) carousel - one of GB, US, CA, AU, Other
  */
  {
    name: "Barbara C.",
    imageFolder: `${cloudfrontLink}Barbara Crowley - Amalfi Coast Bicycle Tour/Barbara`,
    tour: "Amalfi Coast Bicycle Tour",
    quote:
      "We went through 10Adventures not familiar with them at all, and it was the best decision we ever made!",
    url: "https://youtu.be/4Ue6lodusyc",
    country: ["US"],
  },

  {
    name: "Allyson K.",
    imageFolder: `${cloudfrontLink}Allyson Kaybidge - Everest base Camp Trek/Allyson`,
    tour: "Everest Base Camp",
    quote: "Just an amazing experience. Absolutely lifechanging!",
    url: "https://youtube.com/shorts/vYEFTakV5ys",
  },
  {
    name: "Amy D.",
    imageFolder: `${cloudfrontLink}Amy DiFrancesco - XC Skiing in the Dolomites/Amy`,
    tour: "XC Skiing in the Dolomites",
    quote:
      "It was a fantastic trip and Nadine was extremely responsive to all of our questions.",
    url: "https://youtube.com/shorts/blv8AIjpH-w",
  },
  {
    name: "Andrea S.",
    imageFolder: `${cloudfrontLink}Andrea Stach/Andrea`,
    tour: "Full Via Rhona",
    quote:
      "We loved that we could easily customize the trip and add a couple of extra days in Geneva, in Lyon, and finally in Orange.",
    url: "https://youtu.be/dUeq-enFKH4",
  },
  {
    name: "Andrea M.",
    imageFolder: `${cloudfrontLink}Andreia Almeida Marton - Ladakh to Markha Valley Trek/AndreiaAlmeida`,
    tour: "Trekking Ecuador's Volcanoes",
    quote:
      "Our trip was great, we had an amazing time, and I highly recommend 10Adventures.",
    url: "https://youtube.com/shorts/YSYFIyCbXyo",
    carousel: true,
  },
  {
    name: "Andrea M.",
    imageFolder: `${cloudfrontLink}Andreia Almeida Marton - Trekking Ecuador_s Volcanoes/Andreia`,
    tour: "Ladakh - Markha Valley Trek",
    quote:
      "Very kind, very helpful, attentive, and they took care of everything for us!",
    url: "https://youtube.com/shorts/E5wtodazh-c",
  },
  {
    name: "Andrew C.",
    imageFolder: `${cloudfrontLink}Andrew Coe - Portuguese Camino Cycling Tour/Andrew`,
    tour: "Portuguese Camino Cycling Tour",
    quote:
      "Thank you 10Adventures, especially Nadine Gravis for all your help and support.",
    url: "https://youtu.be/LEQVlRwarDU",
    carousel: true,
    country: ["AU"],
  },
  {
    name: "Becky M.",
    imageFolder: `${cloudfrontLink}Becky MacMillan - Camino Portugues/Becky`,
    tour: "Camino Portugues",
    quote: "It was a fantastic experience from beginning to end!",
    url: "https://youtube.com/shorts/kUJA6usGSZo",
  },
  {
    name: "Beth K.",
    imageFolder: `${cloudfrontLink}Beth Kennedy/Beth`,
    tour: "Munich to Venice Cycling Tour",
    quote: "We were well supported and route directions were easy to follow!",
    url: "https://youtu.be/GFouLxrzPfE",
  },
  {
    name: "Beverly M.",
    imageFolder: `${cloudfrontLink}Beverly Ann Menke - Camino Francés/Beverly`,
    tour: "Munich to Venice Cycling Tour",
    quote: "We were well supported and route directions were easy to follow!",
    url: "https://youtube.com/shorts/Xm-MjflaUb8",
  },
  {
    name: "Bill B.",
    imageFolder: `${cloudfrontLink}Bill Black - Torres del Paine O and W Circuit/Bill`,
    tour: "Torres del Paine O and W Circuit",
    quote: "10Adventures arranged everything for us, and what a trip it was!",
    url: "https://youtube.com/shorts/X2-P3OKrEOI",
    country: ["US"],
  },
  {
    name: "Bipin J.",
    imageFolder: `${cloudfrontLink}Bipin Junnarkar - Tour du Mont Blanc/Bipin`,
    tour: "Tour du Mont Blanc",
    quote:
      "We stayed in the best hotels, enjoyed delectable food, and hiked over 100km. Thanks to 10Adventures for making a memorable trip!",
    url: "https://youtu.be/r-cjy6_ffhk",
  },
  {
    name: "Bryn T.",
    imageFolder: `${cloudfrontLink}Bryn Thompson - Camino de Santiago (from St Jean de Pied a Port)/Bryn`,
    tour: "Camino de Santiago (from St Jean de Pied a Port)",
    quote: "It's been superbly organized by 10Adventures all the way.",
    url: "https://youtube.com/shorts/yYMYURkiIlI",
    country: ["GB", "AUS"],
  },
  {
    name: "Carol L.",
    imageFolder: `${cloudfrontLink}Carol Leung - Portuguese Camino in Deluxe Hotels/Carol`,
    tour: "Portuguese Camino in Deluxe Hotels",
    quote:
      "This trip had been on both of our bucketlists for a long time; however, we had no idea how to start it and what to do, until we found 10Adventures.",
    url: "https://youtu.be/4hvpdkfEH48",
    carousel: true,
    country: ["CA"],
  },
  {
    name: "Carol V.",
    imageFolder: `${cloudfrontLink}Carol VandenEngel - Camino Portugues/CarolVanden`,
    tour: "Camino Portuguese",
    quote: "Thank you 10Adventures, it was indeed a buen camino!",
    url: "https://youtu.be/9lPYESGym4w",
  },
  {
    name: "Catharine T.",
    imageFolder: `${cloudfrontLink}Catharine Tombs - Loire Valley/Catharine`,
    tour: "Loire Valley: The Land of Castles Cycling Tour",
    quote:
      "We had an incredibly magical time that will stay with us for a lifetime.",
    url: "https://youtube.com/shorts/3gab3AuwUis",
  },
  {
    name: "Charmain C.",
    imageFolder: `${cloudfrontLink}Charmain Carroll - 8-Day Self-Guided Portuguese/Charmain`,
    tour: "8-Day Self-Guided Portuguese Coastal Camino in Luxury",
    quote: "A bucketlist experience, it was out of this world!",
    url: "https://youtu.be/An1J1ngAQcA",
    carousel: true,
    country: ["GB", "AUS"],
  },
  {
    name: "Chris N.",
    imageFolder: `${cloudfrontLink}Chris Newiss - Camino Frances/Chris`,
    tour: "Camino De Santiago (Frances)",
    quote:
      "10Adventures sorted out all of my accommodations and itinerary, and everything went as smooth as silk.",
    url: "https://youtube.com/shorts/l5b79g31O-A",
    country: ["GB", "AUS"],
  },
  {
    name: "Christie O.",
    imageFolder: `${cloudfrontLink}Christie Ohlendorf - Camino de Santiago (English Way)/Christie`,
    tour: "Camino de Santiago (English Way)",
    quote:
      "It was perfect! I liked the fact knowing that I had somebody to call if I needed any assistance.",
    url: "https://youtube.com/shorts/12ChMtTFbr0",
  },
  {
    name: "Colleen N.",
    imageFolder: `${cloudfrontLink}Colleen Nicholson - 9-Day Self-Guided Rhône River by Bike/Colleen`,
    tour: "9-Day Self-Guided Rhône River by Bike Tour",
    quote:
      "This was a fantastic trip due to the flexibility offered to us by Nadine, our booking agent.",
    url: "https://youtube.com/shorts/63h8cN9tll0",
    country: ["CA"],
  },
  {
    name: "Craig and Ali C.",
    imageFolder: `${cloudfrontLink}Craig and Ali Couper - Cinque Terre 7 day Pilgrimage to Portovenere/CraigC`,
    tour: "Cinque Terre 7 day Pilgrimage to Portovenere",
    quote:
      "Thank you so much 10Adventures! It was amazing and we're really looking forward to booking our next adventure with you.",
    url: "https://youtube.com/shorts/d3er0aEYYb0",
    country: ["GB", "AUS"],
  },
  {
    name: "Denise O.",
    imageFolder: `${cloudfrontLink}Denise Otero/DeniseO`,
    tour: "Camino Francés 100km",
    quote:
      "The trip itself was mindblowing and better than we could have ever expected",
    url: "https://youtu.be/8rsSE9pK_DU",
  },
  {
    name: "Dorian H.",
    imageFolder: `${cloudfrontLink}Dorian Herring /DorianH`,
    tour: "3 Day Aurora Borealis",
    quote: "I had a great trip with 10Adventures!",
    url: "https://youtube.com/shorts/Nv36oSj8w9I",
  },
  {
    name: "Doug H.",
    imageFolder: `${cloudfrontLink}Doug Hommy - Dingle Peninsula /DougH`,
    tour: "Dingle Peninsula Cycling Tour and Alpe Adria Cycle Path Tour",
    quote:
      "We chose 10Adventures because they are a very simply run, but reasonably priced and very well-organized organization.",
    url: "https://youtu.be/WjnDGijA34s",
    country: ["CA"],
  },
  {
    name: "Drew S.",
    imageFolder: `${cloudfrontLink}Drew Stephen - 9-Day Self-Guided Rhône River by Bike/Drew`,
    tour: "9-Day Self-Guided Rhône River by Bike",
    quote: "10Adventures was awesome!",
    url: "https://youtu.be/rPjMeRy7V7E",
    country: ["CA"],
  },
  {
    name: "Eloise R.",
    imageFolder: `${cloudfrontLink}Eloise Rosina - Loire /Elosie`,
    tour: "Loire Valley: The Land of Castles Cycling Tour",
    quote:
      "Our entire trip was very well organized, and we had very detailed instructions we could always resort to.",
    url: "https://youtube.com/shorts/S3-86ICeBrM",
    carousel: true,
    country: ["CA"],
  },
  {
    name: "Euan D.",
    imageFolder: `${cloudfrontLink}Euan Donaldson/Euan`,
    tour: "Cycling Dolomites to Lake Garda",
    quote: "Cycling Dolomites to Lake Garda",
    url: "https://youtu.be/CiYCeXC8fXg",
    country: ["GB", "AUS"],
  },
  {
    name: "Gisèle S.",
    imageFolder: `${cloudfrontLink}Gisèle Samson-Verreault - Porto to Lisbon Cycle Tour/Gisele`,
    tour: "Porto to Lisbon Cycle Tour",
    quote:
      "We couldn't have done this trip without Nadine's help! We had a couple of challenges and she helped us out.",
    url: "https://youtu.be/_OzGva4gXTU",
  },
  {
    name: "Guri-Lise H.",
    imageFolder: `${cloudfrontLink}Guri-Lise Hoesteland /Guri`,
    tour: "Cycling from Porto to Lisbon Tour",
    quote: "Wonderful picnics!",
    url: "https://youtu.be/x_H1r9FH2M0",
  },
  {
    name: "Holly A.",
    imageFolder: `${cloudfrontLink}Holly Atkinson - Piedmont Val Maira Walking tour/Holly`,
    tour: "Piedmont Val Maira Walking Tour",
    quote: "I cannot recommend the tour and 10Adventures enough!",
    url: "https://youtube.com/shorts/Pd79o7FlAGk",
  },
  {
    name: "Jeanne G.",
    imageFolder: `${cloudfrontLink}Jeanne Gillen/Jeanne`,
    tour: "7 Day camino De Santiago (in luxury 😊)",
    quote: "Nadine was my travel guide and expert, and she was wonderful!",
    url: "https://youtu.be/OGH0RkId-DM",
  },
  {
    name: "Jennifer W.",
    imageFolder: `${cloudfrontLink}Jennifer Woods/Jennifer`,
    tour: "9 day multisport adventure in Greece",
    quote: "10Adventures took care of everything!",
    url: "https://youtube.com/shorts/0SvoZRUvkc4",
    country: ["CA"],
  },
  {
    name: "Jillian R.",
    imageFolder: `${cloudfrontLink}Jillian Reid/Jillian`,
    tour: "12 Night Hiking and Camping Through Canadian Rockies",
    quote: "Truly the trip of a lifetime!",
    url: "https://youtu.be/8sk4rtikkdo",
  },
  {
    name: "Jinny M.",
    imageFolder: `${cloudfrontLink}Jinny Mackenzie/Jinny`,
    tour: "Self-Guided Alpilles of Provence Cycling Tour",
    quote:
      "We loved getting our luggage carried and we loved that it was all organized for us!",
    url: "https://youtube.com/shorts/hLcEFUw4kJk",
    country: ["GB", "AUS"],
  },
  {
    name: "Aisling G.",
    imageFolder: `${cloudfrontLink}Aisling Gilligan - Picos de Europa/Aisling Gilligan`,
    tour: "Picos de Europa",
    quote:
      "Really good value for the money, and having booked it I won't be doing any organizing myself any more.",
    url: "https://youtube.com/shorts/PnXJ4-pCjRg",
    carousel: true,
    country: ["GB"],
  },
  {
    name: "Alexander R.",
    imageFolder: `${cloudfrontLink}Alexander Rabinovich - Camino de Santiago (Camino Ingles)/AlexR`,
    tour: "Camino de Santiago, English route",
    quote:
      "We had a great time. The tour was very well organized, we stayed in nice places, and made some friends along the way.",
    url: "https://youtube.com/shorts/gIuU7But1Yw",
  },
  {
    name: "John D.",
    imageFolder: `${cloudfrontLink}John Duffy -/John`,
    tour: "Venice to Croatia Bike Tour",
    quote:
      "Great hotels, good breakfast every day, and good maps that always got us where we needed to go.",
    url: "https://youtube.com/shorts/qj9tYMHt3Q8",
  },
  {
    name: "John O.",
    imageFolder: `${cloudfrontLink}John O_Brien/JohnOBrien`,
    tour: "Camino Frances Saint-Jean-Pied-de-Port to Logrono",
    quote:
      "The price was far better than all of the other companies we had looked at.",
    url: "https://youtu.be/LCtuNp97Cxs",
    country: ["GB", "AUS"],
  },
  {
    name: "Julieanne M.",
    imageFolder: `${cloudfrontLink}Julieanne Moore /JulieanneMoore`,
    tour: "The Complete TMB",
    quote:
      "All we had to do was show up, read the wonderful packet, and use the app that they provided. Such a weight off my shoulders!",
    url: "https://youtube.com/shorts/CJG1XjRd4ew",
  },
  {
    name: "Karen J.",
    imageFolder: `${cloudfrontLink}Karen Jensen /KarenJ`,
    tour: "Tour du Mont Blanc",
    quote: "We're ready for our next adventure!",
    url: "https://youtu.be/uCFTE_vEHaI",
  },
  {
    name: "Katarina P.",
    imageFolder: `${cloudfrontLink}Katarina Podlesnaya/Katarina Podlesnaya`,
    tour: "Walking in Val d’Orcia: Montepulciano to Siena",
    quote: "The trip was absolutely beautiful. I can't wait to go back!",
    url: "https://youtube.com/shorts/nprVni8vBgs",
    country: ["US"],
  },
  {
    name: "Kate D.",
    imageFolder: `${cloudfrontLink}Kate Dietz /kate`,
    tour: "Cotswolds Self Guided Bike Tour",
    quote: "It was a dream. I can't think of a better way to see the world!",
    url: "https://youtu.be/PyE-RD-_zn0",
    country: ["US"],
  },
  {
    name: "Kellie C.",
    imageFolder: `${cloudfrontLink}Kellie Cleminson - Laugavegur Trail/KellieCleminsonL`,
    tour: "Laugavegur Trail",
    quote:
      "This was a dream trip for us and Nadine at 10Adventures planned it out to perfection.",
    url: "https://youtu.be/yRULFT7yZMc",
    country: ["CA"],
  },
  {
    name: "Kelly D.",
    imageFolder: `${cloudfrontLink}Kelly Darnell /KellyD`,
    tour: "Custom Greece Tour",
    quote:
      "From the very beginning, the folks at 10Adventures - Richard and his team - took care of every single detail.",
    url: "https://youtu.be/COP0dl_mBwk",
    country: ["US"],
  },
  {
    name: "Kelly R.",
    imageFolder: `${cloudfrontLink}Kelly Root /KellyR`,
    tour: "Canal Du Midi Bike Tour",
    quote: "10Adventures did a fabulous job of planning!",
    url: "https://youtube.com/shorts/O-iLZIA9gL0",
    carousel: true,
    country: ["US"],
  },
  {
    name: "Kevin N.",
    imageFolder: `${cloudfrontLink}Kevin Nicholson -/Kevin Nicholson`,
    tour: "Rhone River Cycling Customized Trip",
    quote: "Great trip; we all throroughly enjoed it!",
    url: "https://youtube.com/shorts/vkwDoterXQE",
  },
  {
    name: "Kimberley D.",
    imageFolder: `${cloudfrontLink}Kimberley Delius /Kimberley Delius`,
    tour: "Camino Frances in Luxury",
    quote:
      "I didn't have to worry about a thing! Nadine and the team are amazing, I highly recommend them!",
    url: "https://youtube.com/shorts/KF9CvYMAOZI",
    country: ["GB", "AUS"],
  },
  {
    name: "Kirsten S.",
    imageFolder: `${cloudfrontLink}Kirsten Schoenleber /Kirsten Schoenleber`,
    tour: "Il Primitivo",
    quote:
      "The whole process was great! Nadine helped us figure out where we wanted to go and how to block out the trip.",
    url: "https://youtube.com/shorts/46_7vn8JYCU",
    country: ["US"],
  },
  {
    name: "Lavern G.",
    imageFolder: `${cloudfrontLink}Lavern Gravis /Lavern`,
    tour: "Aurora Borealis Tour",
    quote: "The experience was just so wonderful!",
    url: "https://www.youtube.com/shorts/7ZPrhKVEu2A",
    country: ["CA"],
  },
  {
    name: "Lee B.",
    imageFolder: `${cloudfrontLink}Lee Bowen/Lee`,
    tour: "Discover Quebec Self-Drive Tour",
    quote: "It was an awesome adventure!",
    url: "https://youtu.be/Et2tD9vgG2A",
  },
  {
    name: "Leslie L.",
    imageFolder: `${cloudfrontLink}Leslie Loewen/Leslie`,
    tour: "Cycle Venice, Italy to Porec, Croatia",
    quote:
      "From the initial inquiry right through to completion of the trip, the friendly staff was super efficient and the tour went very smoothly.",
    url: "https://youtu.be/lDU6n5ZsRZQ",
  },
  {
    name: "Lisa R.",
    imageFolder: `${cloudfrontLink}Lisa Reich /Lisa Reich`,
    tour: "Alpine Crossing 2: Garmish to Merano Tour",
    quote:
      "10Adventures was able to have everything laid out for me and planned perfectly so that I could literally go from one guesthouse location to the next.",
    url: "https://youtu.be/BJTEjyEopBI",
  },
  {
    name: "Marilyn W.",
    imageFolder: `${cloudfrontLink}Marilyn Watson/Marilyn Watson`,
    tour: "Portuguese Camino from Porto",
    quote: "It's an awesome adventure. Don't wait, take yours!",
    url: "https://youtube.com/shorts/ihFVfEXZVPE",
  },
  {
    name: "Martha M.",
    imageFolder: `${cloudfrontLink}Martha McCabe/Martha`,
    tour: "Tour du Mont Blanc",
    quote:
      "It was a lot easier going through 10Adventures, I honestly don't think we could have booked our trip without that support.",
    url: "https://youtube.com/shorts/dVu9gnwhE1k",
  },
  {
    name: "Maureen W.",
    imageFolder: `${cloudfrontLink}Maureen Wright /Maureen`,
    tour: "Minho and Porto Cycling Adventure",
    quote:
      "Nadine was a great help in assisting us with some minor tweaks to the itinerary, and while we were there we found the organization, the planning, and support were brilliant.",
    url: "https://youtu.be/k4MijDYa2QM",
    country: ["GB", "AUS"],
  },
  {
    name: "Max W.",
    imageFolder: `${cloudfrontLink}Max Wiseman/Max`,
    tour: "Piedmont Val Maira Self Guided Hiking Tour",
    quote:
      "Everything was super well-organized by 10Adventures, they were very accommodating and communicative",
    url: "https://youtube.com/shorts/5KE4LvjHgWI",
  },
  {
    name: "Michael S.",
    imageFolder: `${cloudfrontLink}Michael Schoenleber /Michael Schoenleber`,
    tour: "El Camino Primitivo",
    quote: "This was absolutely the trip of a lifetime!",
    url: "https://youtube.com/shorts/ydrZDkf92hA",
  },
  {
    name: "Mei Ling H.",
    imageFolder: `${cloudfrontLink}Mei Ling Han/MeiLing`,
    tour: "Camino Frances (Sep 23)",
    quote:
      "I would like to express my appreciation to 10Adventures for the incredible experience on my first Camino Frances",
    url: "https://youtube.com/shorts/90SjSmA7l8A",
    carousel: true,
  },

  {
    name: "Michelle H.",
    imageFolder: `${cloudfrontLink}Michelle Hogan/MichelleH`,
    tour: "Tour du Mont Blanc",
    quote: "Thanks 10Adventures!",
    url: "https://youtu.be/Vh1XEh5mpIU",
  },
  {
    name: "Kellie C.",
    imageFolder: `${cloudfrontLink}Kellie Cleminson - Tour du Mont Blanc/KellieCTour`,
    tour: "Tour du Mont Blanc",
    quote:
      "10Adventures was our obvious choice when we decided to hike the Tour du Mont Blanc.",
    url: "https://youtu.be/P-R0E-vVo_o",
  },
  {
    name: "Mike C.",
    imageFolder: `${cloudfrontLink}Mike Cleminson/MikeC`,
    tour: "Tour du Mont Blanc",
    quote:
      "This wasn't my first time travelling with 10Adventures, and it certainly won't be my last!",
    url: "https://youtu.be/AzfI5I99EF8",
  },
  {
    name: "Nancy E.",
    imageFolder: `${cloudfrontLink}Nancy Early/NancyE`,
    tour: "Portugal Sintra-Cascais",
    quote: "Thank you 10Adventures for some unforgettable experiences!",
    url: "https://youtube.com/shorts/MUIlCkxsUaI",
  },
  {
    name: "Nicole G.",
    imageFolder: `${cloudfrontLink}Nicole Gionet /Nicole`,
    tour: "Porto - Lisbon cycling",
    quote:
      "It was absolutely beautiful! We loved the service from 10Adventures.",
    url: "https://youtube.com/shorts/lzImWGutb1c",
  },
  {
    name: "Rachel D.",
    imageFolder: `${cloudfrontLink}Rachel Duffy/Rachel`,
    tour: "Venice to Croatia Cycling Tour",
    quote:
      "Can't say enough good things about 10Adventures. Communication with Nadine as we planned was spectacular!",
    url: "https://youtube.com/shorts/FSLvFS_3BGo",
    country: ["US"],
  },
  {
    name: "Reid C.",
    imageFolder: `${cloudfrontLink}Reid Covey/Reid`,
    tour: "Kitzbuhel to Drei Zinnen",
    quote:
      "I had the pleasure of doing a 10Adventures trip this summer and it was honestly one of the most amazing experiences of my life.",
    url: "https://youtu.be/HBAzUn4fDqY",
  },
  {
    name: "Renee H.",
    imageFolder: `${cloudfrontLink}Renee Hewitt -/Renee`,
    tour: "Venice to Croatia",
    quote:
      "We're just really appreciative of the experience and all of the help that the team gave, it was super smooth.",
    url: "https://youtube.com/shorts/2Lr1oJ40opc",
    country: ["CA"],
  },
  {
    name: "Renee S.",
    imageFolder: `${cloudfrontLink}Renee Stanford/ReneeS`,
    tour: "Tour du Mont Blanc",
    quote:
      "The accommodations that they booked for us were amazing, better than we would have picked on our own!",
    url: "https://youtube.com/shorts/YOVYxafq6yw",
    country: ["US"],
  },
  {
    name: "Rich G.",
    imageFolder: `${cloudfrontLink}Rich Gross /Richg`,
    tour: "Patagonia O Circuit",
    quote:
      "Nadine and 10Adventures were just fantastic in setting up the trip and all of the logistics",
    url: "https://youtube.com/shorts/tgb_sFcHBKI",
    carousel: true,
  },
  {
    name: "Robert P.",
    imageFolder: `${cloudfrontLink}Robert Parker /RobertP`,
    tour: "Moselle Valley 6 Day Self Guided Cycle Tour",
    quote: "Highly recommend the 6-Day Moselle tour with 10Adventures.",
    url: "https://youtube.com/shorts/uyX15H47jNk",
  },
  {
    name: "Roberta F.",
    imageFolder: `${cloudfrontLink}Roberta Fuller/Roberta`,
    tour: "Camino de Santiago",
    quote: "10Adventures have been amazing communicators!",
    url: "https://youtu.be/clNTIcRtlaI",
  },
  {
    name: "Rodney P.",
    imageFolder: `${cloudfrontLink}Rodney Puumala /Rodney`,
    tour: "Full Via Rhona",
    quote: "We had a fantastic time! The bikes were excellent",
    url: "https://youtube.com/shorts/1qDtEvdW_hc",
  },
  {
    name: "Sarah C.",
    imageFolder: `${cloudfrontLink}Sarah Carpenter -/SarahC`,
    tour: "X-Country Skiing in the Dolomites",
    quote:
      "We had a wonderful time, in no small part due to the wonderful support that 10Adventures gave us.",
    url: "https://youtu.be/gOVP7FJxw9M",
  },
  {
    name: "Sawyer P.",
    imageFolder: `${cloudfrontLink}Sawyer Puumala/Sawyer`,
    tour: "Full Via Rhona",
    quote: "Very amazing and very scenic!",
    url: "https://youtube.com/shorts/ILdyQdzgeHE",
  },
  {
    name: "Lesley C.",
    imageFolder: `${cloudfrontLink}Lesley Clark/Lesley`,
    tour: "Discover Quebec Self-Drive Tour",
    quote: "Thank you Nadine for coordinating our discover Quebec trip!",
    url: "https://youtu.be/Et2tD9vgG2A",
  },
  {
    name: "Shahryar T.",
    imageFolder: `${cloudfrontLink}Shahryar Talukder/Shahryar`,
    tour: "8 Day Hike the Alpe Adria Trail Tour",
    quote:
      "Nadine and Richard have been outstanding in the way they have met our specific requirements and the flexibility they have shown.",
    url: "https://youtu.be/D3SJKHf-fSI",
  },
  {
    name: "Shelley S.",
    imageFolder: `${cloudfrontLink}Shelley Sink /Shelley`,
    tour: "Ecuador Volcano Trek",
    quote:
      "Wonderful guides, the trip was well-organized, the food was delicious",
    url: "https://youtu.be/J11osX80FsM",
    carousel: true,
    country: ["US"],
  },
  {
    name: "Skye L.",
    imageFolder: `${cloudfrontLink}Skye Large/Skye`,
    tour: "Walking the Camino",
    quote:
      "Every step I took was really magical, and it was a lifechanging adventure.",
    url: "https://youtu.be/-US7ltc6144",
  },
  {
    name: "Stacey C.",
    imageFolder: `${cloudfrontLink}Stacey Copeland /Stacey`,
    tour: "Dolomites to Lake Garda",
    quote:
      "Having retired from professional sport a couple of years ago, it was just exactly the challenging adventure that I needed.",
    url: "https://youtube.com/shorts/koUp44NOqcw",
    country: ["GB"],
  },
  {
    name: "Sue W.",
    imageFolder: `${cloudfrontLink}Sue Walders/Sue`,
    tour: "Dana to Petra Jordan",
    quote: "I can't even describe it, it was so wonderful!",
    url: "https://youtu.be/6xkNbWNcSH8",
    country: ["CA"],
  },
  {
    name: "Svetlana L.",
    imageFolder: `${cloudfrontLink}Svetlana Loksh /Svetlana`,
    tour: "Alta Via Dolomites",
    quote: "The experience of planning the trip was outstanding!",
    url: "https://youtu.be/kbMf5XJkTA4",
  },
  {
    name: "Sylvia F.",
    imageFolder: `${cloudfrontLink}Sylvia Frehner/Sylvia`,
    tour: "Porto to Lisbon Bike Tour",
    quote:
      "It was all a fantastic experience, and a lot of it was due to the support and good service that was offered from Nadine from 10Adventures.",
    url: "https://youtube.com/shorts/iYs7o2CZ0sk",
  },
  {
    name: "Teresa B.",
    imageFolder: `${cloudfrontLink}Teresa Boyer/Teresa`,
    tour: "West Highland Way 7 Day Hike in Scotland",
    quote:
      "10Adventures answered all of my email questions fast and promptly, and even checked in with us when we were on our hike to see how we were doing.",
    url: "https://youtube.com/shorts/GEFHWWH91SI",
  },
  {
    name: "Timothy K.",
    imageFolder: `${cloudfrontLink}Timothy Kelley/Timothy`,
    tour: "Vanoise Loop",
    quote:
      "I couldn't be happier with how things turned out, specifically Nadine helped me before, after, and during the hike.",
    url: "https://youtu.be/OnQ3LJvB62U",
  },
  {
    name: "Tony W.",
    imageFolder: `${cloudfrontLink}Tony Walsh/TonyW`,
    tour: "Slower Pace Camino de Santiago",
    quote:
      "Just like my first camino, all I have to worry about are my feet. Thanks 10Adventures!",
    url: "https://youtube.com/shorts/kT4NdYAaFUc",
  },
  {
    name: "Uwe P.",
    imageFolder: `${cloudfrontLink}Uwe Pomowski /Uwe`,
    tour: "Tenerife South to North Walking Tour",
    quote:
      "The contact with 10Adventures, especially with Nadine, was excellent and very personal",
    url: "https://youtube.com/shorts/UDnFKozb3nI",
    carousel: true,
  },
  {
    name: "Vanessa V.",
    imageFolder: `${cloudfrontLink}Vanessa Vieira Vaz /Vanessa`,
    tour: "Avenue of the Volcanoes 2019 and Markha Valley Trek 2022",
    quote: "10Adventures took care of every detail!",
    url: "https://youtube.com/shorts/R-bV1UfSXb8",
  },
  {
    name: "Wai Lee Y.",
    imageFolder: `${cloudfrontLink}Wai Lee Yang/WaiLee`,
    tour: "10-Day Camino de Santiago in Luxury",
    quote:
      "It was an exhiliarating and awesome experience that exceeded all my expectations!",
    url: "https://youtu.be/Sker_RumU_I",
  },
]
